import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomPaginatorIntl extends MatPaginatorIntl {
  itemsPerPageLabel = 'Éléments par page :';
  nextPageLabel = 'Page suivante';
  previousPageLabel = 'Page précédente';
  // Ajoutez d'autres propriétés si nécessaire
}
