import { Component, inject, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { NavigationService } from './core/navigation/navigation.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet],
})
export class AppComponent implements OnChanges {
    /**
     * Constructor
     */
     constructor(private navigationService: NavigationService, private renderer: Renderer2, private activatedRoute: ActivatedRoute) {
        //  this.renderer.setStyle(document.documentElement, '--dynamic-color', environment.PRIMARY_COLOR);
        this.navigationService.setupNavigation();
        this.activatedRoute.paramMap.subscribe(
            params => {
                this.navigationService.setupNavigation();
            }
        )
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.navigationService.setupNavigation();
    }
}
