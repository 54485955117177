import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from './core/auth/auth.service';

export const appInterceptor: HttpInterceptorFn = (req, next) => {
    const authService = inject(AuthService)
    const authReq = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + authService.getToken())
    });
    return next(authReq);
};
